import React, { useEffect, useState } from 'react';
import UploadList from '../../UploadListTab/UploadList';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { history } from '../history/history';
import { useTranslation } from "react-i18next";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const App = (props) => {
  const {selectedItem, setSelectedItem} = props;
  const { t, i18n } = useTranslation('common');
  const [collapsed, setCollapsed] = useState(true); 
  const items = [
    getItem(t('Header.home'), '1', <PieChartOutlined />),
    getItem(t('Header.fileupload'), '2', <PieChartOutlined />),
    getItem(t('Header.chartIt'), '3', <DesktopOutlined />),
    getItem(t('Header.dashboard'), '4', <ContainerOutlined />),
    getItem(t('Header.collection'), '5', <ContainerOutlined />),
    getItem(t('Header.pivot'), '6', <ContainerOutlined />),
    getItem(t('Header.presentation'), '7', <ContainerOutlined />),
    getItem(t('Header.about'), '8', <ContainerOutlined />),    
  ]; 

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setSelectedItem(localStorage.getItem("selectedItem"));
  };
  
  const handleMenuItemClick = (item) => {   
    setCollapsed(!collapsed); 
    setSelectedItem(item.key);
    localStorage.setItem("selectedItem", item.key);
    history.push('/home');
  };
  useEffect(() => {
    setSelectedItem(localStorage.getItem("selectedItem"));
  },[])
  return (
    <div
      style={{
        width: 256,
      }}
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginTop: 3,
          backgroundColor: "#00f",
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      {!collapsed && (
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={items}
        onClick={handleMenuItemClick}
      />
      )}
    </div>
  );
};
export default App;